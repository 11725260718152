@font-face {
  font-family: "Matter";
  font-weight: 400;
  src: url(/Matter-Regular.woff2) format("woff2");
}
html,input,button{
  font-family: Matter,Rajdhani,Montserrat,Arial;
}
body{
  margin:0;
  scrollbar-width: none;
  overflow-x:hidden;
}
body::-webkit-scrollbar { display:none;}
div{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-grow:1;
  width:100%; 
  /* box-sizing: border-box; */
}
input, button, div{
  border-radius: 0px;
}
input:focus, button:focus {
  outline:none;
  box-shadow: 0 0 3pt 2pt blue;
}
.react-toggle{
  flex-grow:0 !important;
  width:auto !important;
}
input{
  border:none;
  padding:5px 10px;
  font-size: 1rem;
}
button{
  cursor: pointer;
}
.__react_component_tooltip{
  width:auto;
  flex-grow:0;
  font-size: 1.2rem !important;
}

.Modal {
  position: absolute;
  width:80vw;
  height:90vh;
  display:flex;
  background-color: white;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:20px;
  outline: none !important;
  overflow-y: scroll;
  display: block;
}
@media (max-width: 600px) {
  .Modal{
    justify-content:unset;
  }
}


.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(1,1,1,0.3);
  display:flex;
  justify-content: center;
  align-items: center;
}

th,td{
  padding:10px !important;
  border-radius:0;
  border-bottom:1px solid #f6f6f6;
}
#live-chat-widget{
  display:unset;
  width:auto;
}

.pac-container{
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
